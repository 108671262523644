export default {
  name: '',
  path: 'system-analytics',
  component: () => import('@/views/systemAnalytics/SystemAnalyticsIndex.vue'),
  children: [
    {
      path: '',
      name: 'system-analytics',
      component: () => import('@/views/systemAnalytics/SystemAnalytics.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          { text: 'routes.system_analytics', exact: true, to: { name: 'system-analytics' }, disabled: true },
        ],
      },
    },
    {
      path: 'create',
      name: 'system-analytics-create',
      component: () => import('@/views/systemAnalytics/SystemAnalyticsCreate.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          { text: 'routes.system_analytics', exact: true, to: { name: 'system-analytics' } },
          { text: 'routes.system_analytics_create' },
        ],
      },
    },
    {
      path: 'update/:id',
      name: 'system-analytics-update',
      component: () => import('@/views/systemAnalytics/SystemAnalyticsUpdate.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [
          { text: 'routes.system_analytics', exact: true, to: { name: 'system-analytics' } },
          { text: 'routes.system_analytics_edit' },
        ],
      },
    },
  ],
};
