import api from '@/api';
import { ANALYTICS_SERVICE } from './constants';

export interface TurnoverChartData {
  id: string; // ID конкретного "куба" с аналитикой
  settlement_day: string; // дата статистики
  value: {
    deposit: number; // Сумма депозитов
    payout: number; // Сумма выплат
    transfer: number; // Сумма трансферов
    invoice: number; // Сумма инвойсов
    total: number; // Всего
  };
}

export interface StatisticsChartData {
  currencies: {
    [iso: string]: {
      complete: {
        count: number;
        amount: number;
      };
      declined: {
        count: number;
        amount: number;
      };
      success_rate: number;
      share: number;
    };
  };
}

interface TransactionStats {
  count: number;
  percent: number;
}

interface TransactionTypeStats {
  amount: number;
  success_rate: number;
  success: TransactionStats;
  failed: TransactionStats;
  canceled: TransactionStats;
  in_progress: TransactionStats;
}

export interface ConversionChartData {
  deposits: TransactionTypeStats;
  payouts: TransactionTypeStats;
  totals: TransactionTypeStats;
}

export interface DashboardChart<T = any> {
  available_currencies: string[];
  chart_type: 'income' | 'turnover' | 'conversion' | 'statistics';
  currency: string;
  data: T; // TODO: add other types
  id: string;
  merchant_id: number;
  settlement_day_from: string;
  settlement_day_to: string;
}

export default {
  systemAnalyticsGet: async (params: any) => {
    return await api.get(`/${ANALYTICS_SERVICE}/charts/`, {
      params,
    });
  },
  systemAnalyticsSearch: async (params: any) => {
    return await api.get(`/${ANALYTICS_SERVICE}/charts/search/`, {
      params,
    });
  },
  systemAnalyticsGetOne: async ({ id }: any) => {
    return await api.get(`/${ANALYTICS_SERVICE}/charts/${id}/`);
  },
  systemAnalyticsSet: async (params: any) => {
    return await api.post(`/${ANALYTICS_SERVICE}/charts/`, params);
  },
  systemAnalyticsUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${ANALYTICS_SERVICE}/charts/${id}/`, params);
  },
  systemAnalyticsDelete: async (params: Record<string, unknown>) => {
    return await api.delete(`/${ANALYTICS_SERVICE}/charts/${params.id}/`);
  },

  dashboardGet: async (params: any) => {
    return await api.get<{ result: DashboardChart[] }>(`/${ANALYTICS_SERVICE}/dashboard/`, {
      params,
    });
  },

  dashboardChartGet: async ({ id, ...params }: any) => {
    return await api.get(`/${ANALYTICS_SERVICE}/charts/${id}/data/`, {
      params,
    });
  },
};
