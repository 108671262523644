export const DEFAULT_SERVICE = 'cpca';
export const REPORTS_REGISTRIES_SERVICE = 'cprm';
export const CARDS_SERVICE = 'cpcm';
export const CRYPTO_GATEWAYS_SERVICE = 'cpcg';
export const COMMISSIONS_SERVICE = 'cpcom';
export const TECH_PAYOUTS_SERVICE = 'cppm';
export const VOUCHERS_SERVICE = 'cpvm';
export const EXCHANGE_SERVICE = 'cpem';
export const EXCHANGE_OFFICE = 'cpeo';
export const AML_SERVICE = 'cpab';
export const LOGS_SERVICE = 'cpls';
export const ANALYTICS_SERVICE = 'cpal';
